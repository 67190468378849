<template>
  <v-container fluid>
    <v-card flat outlined class="pa-sm-4 wraperx">
      <v-card-title>
        <v-btn icon class="me-2" @click="$router.go(-1)">
          <v-icon> mdi-arrow-left </v-icon>
        </v-btn>
        Role Information
        <v-spacer></v-spacer>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12" md="8">
            <v-sheet class="mb-4" color="transparent">
              <v-sheet class="text-caption" color="transparent"
                >Role name</v-sheet
              >
              <v-skeleton-loader
                type="card-heading"
                v-if="loading"
              ></v-skeleton-loader>
              <h2 class="text--primary large font-weight-medium" v-else>
                {{ role.name }}
              </h2>
            </v-sheet>
            <v-sheet class="mb-4" max-width="480" color="transparent">
              <v-sheet class="text-caption" color="transparent"
                >Description</v-sheet
              >
              <v-skeleton-loader
                type="paragraph"
                v-if="loading"
              ></v-skeleton-loader>
              <p class="text--primary" v-else>
                {{ role.description }}
              </p>
            </v-sheet>
          </v-col>
          <v-col cols="12" md="4" v-if="staff.length > 0">
            <v-skeleton-loader
              type="card-heading"
              v-if="dataLoading"
            ></v-skeleton-loader>
            <v-card flat outlined v-else>
              <v-sheet color="transparent" class="pa-2">
                Staff Assigned to this Role ({{ staff.length }})
              </v-sheet>
              <v-divider></v-divider>
              <v-sheet
                color="transparent"
                max-height="300"
                style="overflow-y: auto"
              >
                <v-list>
                  <v-list-item
                    v-for="person in staff"
                    :key="person.uuid"
                    link
                    @click="viewStaff(person)"
                  >
                    <v-list-item-avatar
                      :color="
                        '#' + ((Math.random() * 0xffffff) << 0).toString(16)
                      "
                      size="30"
                      class="d-flex justify-center"
                      style="position: relative"
                    >
                      <div class="white--text" style="position: absolute">
                        {{ person.first_name.charAt(0).toUpperCase()
                        }}{{ person.last_name.charAt(0).toUpperCase() }}
                      </div>
                      <v-img
                        :src="person.profile"
                        alt="person.first_name"
                        v-if="person.profile"
                      />
                    </v-list-item-avatar>

                    <v-list-item-content>
                      <v-list-item-title>
                        {{
                          person.first_name + " " + person.last_name
                        }}</v-list-item-title
                      >
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-sheet>
            </v-card>
          </v-col>
        </v-row>

        <h3 class="primary--text large font-weight-medium mb-3 mt-5">
          Modules & Permissions
        </h3>
        <v-row class="pb-4" v-if="dataLoading">
          <v-col cols="12" md="6" v-for="i in 10" :key="i">
            <v-skeleton-loader type="list-item-three-line"></v-skeleton-loader>
          </v-col>
        </v-row>

        <v-row class="pb-4" v-else>
          <v-col cols="12" md="6" v-for="module in modules" :key="module.id">
            <GroupChecks
              :rolePermissions="rolePermissions"
              :module="module"
              :readonly="true"
            />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <v-card
      flat
      outlined
      class="pa-4 pa-sm-6 mt-8"
      v-if="
        ($_checkPermission(auth.permissions, 'Manage Role', 'Delete') ||
          $_checkPermission(auth.permissions, 'Manage Role', 'Update')) &&
        role.name != 'Admin'
      "
    >
      <v-sheet class="mb-5 text-h6" color="transparent">
        Administrator Actions
      </v-sheet>
      <v-row v-if="loading">
        <v-col cols="12" v-for="i in 2" :key="i + 14">
          <v-skeleton-loader type="sentences"></v-skeleton-loader>
        </v-col>
      </v-row>
      <v-list color="actionList" v-else>
        <v-list-item
          three-line
          v-if="$_checkPermission(auth.permissions, 'Manage Role', 'Update')"
        >
          <v-list-item-content>
            <v-list-item-title>Edit Role</v-list-item-title>
            <v-list-item-subtitle>
              Edit role permissions to add, update, or remove access rights.
              <span class="d-none d-md-inline">
                Changes apply immediately, impacting all users assigned to the
                role.
              </span>
            </v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action>
            <v-btn depressed color="primary" outlined @click="editItem()">
              <v-icon left> mdi-pencil </v-icon>
              Edit
            </v-btn>
          </v-list-item-action>
        </v-list-item>

        <v-divider
          v-if="$_checkPermission(auth.permissions, 'Manage Role', 'Delete')"
        ></v-divider>

        <v-list-item
          three-line
          v-if="$_checkPermission(auth.permissions, 'Manage Role', 'Delete')"
        >
          <v-list-item-content>
            <v-list-item-title> Delete Role</v-list-item-title>
            <v-list-item-subtitle>
              A role can only be deleted if it’s not assigned to any staff.
              <span class="d-none d-md-inline">
                This action is irreversible through the app and can only be
                restored by a database admin.
              </span>
            </v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action>
            <v-btn
              depressed
              color="error"
              outlined
              @click="deleteDialog = true"
            >
              <v-icon left>mdi-delete</v-icon>
              Delete
            </v-btn>
          </v-list-item-action>
        </v-list-item>
      </v-list>
    </v-card>

    <v-dialog v-model="deleteDialog" persistent max-width="290">
      <v-card outlined>
        <v-card-title class="text-h5"> Confirmation Needed </v-card-title>
        <v-card-text>
          Are you sure you want to delete this role? This action cannot be
          undone.
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            text
            @click="deleteRole()"
            :loading="deleteLoading"
          >
            Delete Role
          </v-btn>
          <v-btn color="primary" text @click="deleteDialog = false">
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
    
    
    <script>
import { mapState } from "vuex";
import { fetchAndStorePermissionModules } from "@/services/preloadDataService";
import {
  getRole,
  deleteRole,
  getStaff,
  syncOfflineRolesChanges,
} from "@/services/rolesService";
export default {
  components: {
    GroupChecks: () =>
      import(
        /* webpackChunkName: "component-group-checks" */ "@/components/GroupChecks"
      ),
  },
  computed: mapState({
    auth: (state) => state.auth.data,
  }),
  props: [
    //
  ],
  data: () => ({
    loading: false,
    dataLoading: false,
    deleteLoading: false,
    role: {},
    getRoleReturn: null,
    rolePermissions: [],
    modules: [],
    staff: [],
    deleteDialog: false,
    deleteRoleReturn: null,
  }),
  async created() {
    if (this.$_checkPermission(this.auth.permissions, "Manage Role", "Read")) {
      this.loading = true;
      await syncOfflineRolesChanges();
      this.getRoleReturn = await getRole(this.$route.params.uuid);
      this.role = this.getRoleReturn;
      this.getRoleReturn.role_permissions.forEach((permission) => {
        this.rolePermissions.push(permission.permission_id);
      });
      this.loading = false;

      this.dataLoading = true;
      this.modules = await fetchAndStorePermissionModules();
      this.staff = await getStaff(this.$route.params.uuid);
      this.dataLoading = false;
    } else {
      this.$router.push({
        name: "PageDashboard",
      });
    }
  },
  mounted() {
    //
  },
  methods: {
    goBack() {
      this.$router.push({ name: "PageRoleList" });
    },
    editItem() {
      this.$router.push({
        name: "PageEditRole",
        params: { uuid: this.role.uuid },
        query: { bc: this.role.name },
      });
    },
    viewStaff(item) {
      this.$router.push({
        name: "PageViewStaff",
        params: { uuid: item.uuid },
        query: { bc: item.first_name + " " + item.last_name },
      });
    },
    async deleteRole() {
      let data = {
        uuid: this.role.uuid,
      };
      this.deleteLoading = true;
      this.deleteRoleReturn = await deleteRole(data);
      this.deleteLoading = false;
      if (this.deleteRoleReturn == "success") {
        this.deleteDialog = false;
        this.goBack();
      }
    },
  },
};
</script>